import dayjs from 'dayjs';
import { Environment } from '~/enums/Environment';
import { getLocale } from '~/services/locale';
import { getDateFormat } from './date-formatter';

// env and API
export const isEnvironment = (...environments: Environment[]): boolean => {
  for (let i = 0; i < environments.length; i++) {
    if (REACT_APP_ENV === environments[i]) {
      return true;
    }
  }

  return false;
};

const galaworkApiTokenKey = 'galawork_api_token';

export const getGalaworkApiToken = (): string | null => {
  const jwtToken = localStorage.getItem(galaworkApiTokenKey);

  if (typeof jwtToken === 'undefined') {
    return null;
  }

  return jwtToken;
};

export const setGalaworkApiToken = (jwtToken: string) => {
  localStorage.setItem(galaworkApiTokenKey, jwtToken);
};

// date and time
export const formatDate = (dateString: string | null | undefined): string => {
  if (dateString && dayjs(dateString).isValid()) {
    return dayjs(dateString).format(getDateFormat());
  }
  return '—';
};

export const convertTimeInSeconds = (
  timeInSeconds: number | null | undefined
): { hours: number; minutes: number; seconds: number } => {
  const validTimeInSeconds = timeInSeconds ?? 0;
  const absTimeInSeconds = Math.abs(validTimeInSeconds);
  const hours = Math.floor(absTimeInSeconds / 3600);
  const minutes = Math.floor((absTimeInSeconds % 3600) / 60);
  const seconds = absTimeInSeconds % 60;

  return {
    hours: validTimeInSeconds < 0 && hours !== 0 ? -hours : hours === 0 ? 0 : hours,
    minutes: validTimeInSeconds < 0 && minutes !== 0 ? -minutes : minutes === 0 ? 0 : minutes,
    seconds: validTimeInSeconds < 0 && seconds !== 0 ? -seconds : seconds === 0 ? 0 : seconds,
  };
};

export const secondsToHours = (seconds: number): number => seconds / 3600;
export const minutesToHours = (seconds: number): number => seconds / 60;

// general
export function insertAt<T>(array: T[], index: number, item: T): T[] {
  // Create a new array with the item inserted at the specified index
  return [...array.slice(0, index), item, ...array.slice(index)];
}

export function sorNumbers(a: number | null | undefined, b: number | null | undefined): number {
  const isANumeric = typeof a === 'number' && !isNaN(a);
  const isBNumeric = typeof b === 'number' && !isNaN(b);

  if (!isANumeric && !isBNumeric) return 0; // Both are not numbers, treat as equal
  if (!isANumeric) return 1; // Non-numeric values come last
  if (!isBNumeric) return -1; // Non-numeric values come last

  return a - b; // Numeric comparison
}

export function sortStrings(a: string | undefined | null, b: string | undefined | null): number {
  const valueA = a ?? ''; // Treat undefined or null as an empty string
  const valueB = b ?? ''; // Treat undefined or null as an empty string

  const isNumericA = !isNaN(Number(valueA));
  const isNumericB = !isNaN(Number(valueB));

  if (isNumericA && isNumericB) {
    // Compare as numbers if both values are numeric strings
    return Number(valueA) - Number(valueB);
  }

  // Fallback to regular string comparison for non-numeric values
  return valueA.localeCompare(valueB, getLocale(), { numeric: true });
}
